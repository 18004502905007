import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "../../app/App.css";
import AccessPending from "./PendingConsents";
import { UserContext } from "../../services/user";
import {
  ConsentStatus,
  dataCategory,
  listConsents,
  listOverallConsents,
  userAffiliation,
} from "../../types";
import showToast from "../../components/toast";
import { formatDate } from "../../utils/dateTimeUtils";
import ConsentCard from "./ConsentCard";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../components/ui/pagination";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../components/ui/alert-dialog";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import Loading from "../../components/loading";
import Card4Confirmation from "./tabs/Card4Confirmation";
import { MdAdd, MdOutlineRefresh } from "react-icons/md";
import { FaRegEye, FaRegTrashAlt } from "react-icons/fa";
import { Input } from "../../components/ui/input";
import ToolTip from "../../components/ui/tooltip";
import { UserContextWithUserType } from "../../shared/UserContentWithUser";
import { PAGE_SIZE, SEARCH_DEBOUNCE_TIME } from "../../environment/variables";
import { useDebounceSearch } from "../../utils/debounceSearch";

const ListConsents = () => {
  // User context
  const userContext = useContext(UserContext) as UserContextWithUserType;
  const activeAffiliationRef = useRef<undefined | userAffiliation>(
    userContext.activeAffiliation
  );

  // State variables
  const [consents, setConsents] = useState<listConsents[]>([]);
  const [status, setStatus] = useState<ConsentStatus>(ConsentStatus.ACTIVE); // Filter
  const [pendingCount, setPendingCount] = useState(0);
  const [maxPages, setMaxPages] = useState(1);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogClose = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  // Input variables
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounceSearch(search, SEARCH_DEBOUNCE_TIME);

  const searchConsents = useMemo(() => {
    return filterConsents(consents, debouncedSearch);
  }, [consents, debouncedSearch]);

  const [isLoading, setIsLoading] = useState(true);
  const [deleteConsent, setDeleteConsent] = useState<
    listConsents | undefined
  >();

  useEffect(() => {
    if (userContext.activeAffiliation !== activeAffiliationRef.current) {
      setIsLoading(true); // Only reset loading if the activeAffiliation changes and not status changes
      activeAffiliationRef.current = userContext.activeAffiliation;
    }
    fetchConsents();
  }, [status, userContext.activeAffiliation]);

  const fetchConsents = async () => {
    setIsLoading(true);
    try {
      if (!userContext.activeAffiliation) {
        const overallConsents = (
          await userContext.axiosInstance.get("consent/my", {
            params: {
              status: status,
              page: page,
            },
            withCredentials: true,
          })
        ).data as listOverallConsents;
        // Fix dates to being set correctly
        const consents = overallConsents.consents.map(
          (consent: listConsents) => ({
            ...consent,
            startsAt: consent.startsAt ? new Date(consent.startsAt) : undefined,
            expiresAt: consent.expiresAt
              ? new Date(consent.expiresAt)
              : undefined,
          })
        );
        setConsents(consents);
        setPendingCount(overallConsents.pendingCount || 0);
        setMaxPages(Math.max(1, Math.ceil(overallConsents.length / PAGE_SIZE)));
      } else {
        const overallConsents = (
          await userContext.axiosInstance.get("consent/asked/", {
            params: {
              status: status,
              page: page,
            },
            withCredentials: true,
          })
        ).data as listOverallConsents;
        setConsents(
          overallConsents.consents.map((consent: listConsents) => ({
            ...consent,
            startsAt: consent.startsAt ? new Date(consent.startsAt) : undefined,
            expiresAt: consent.expiresAt
              ? new Date(consent.expiresAt)
              : undefined,
          }))
        );
        setMaxPages(Math.ceil(overallConsents.length / PAGE_SIZE));
      }
    } catch (error) {
      console.error("Error fetching consents:", error);
    } finally {
      setIsLoading(false);
    }
  };

  function filterConsents(consents: listConsents[], searchString: string) {
    if (consents.length === 0) return [];
    const normalizedSearchString = searchString.toLowerCase();
    return consents.filter((consent) => {
      // Normalize and check if searchString is in sharingName
      const sharingNameMatch = consent.sharingName
        ?.toLowerCase()
        .includes(normalizedSearchString);

      // Normalize and check if searchString is in any of the sharingWithAffiliations names
      const sharingWithAffiliationsMatch =
        consent.sharingWithAffiliations?.some((affiliation: { name: string }) =>
          affiliation.name.toLowerCase().includes(normalizedSearchString)
        );

      // Normalize and check if searchString is in any of the sharingWithDepartmensNames
      const sharingWithDepartmentsNamesMatch =
        consent.sharingWithDepartmentsNames?.some((name: string) =>
          name.toLowerCase().includes(normalizedSearchString)
        );

      // Normalize and check if searchString is in sharingWithOrganizationName
      const sharingWithOrganizationNameMatch =
        consent.sharingWithOrganizationName
          ?.toLowerCase()
          .includes(normalizedSearchString);

      // Normalize and check if searchString is in categoriesNames
      const categoriesNamesMatch = consent.categories?.some(
        (category: dataCategory) =>
          category.name.toLowerCase().includes(normalizedSearchString)
      );

      // Combine all checks with OR to filter in any match
      return (
        sharingNameMatch ||
        sharingWithAffiliationsMatch ||
        sharingWithDepartmentsNamesMatch ||
        sharingWithOrganizationNameMatch ||
        categoriesNamesMatch
      );
    });
  }

  const revokeConsent = async () => {
    //console.log("revoke", deleteConsent);
    if (!deleteConsent) return;
    try {
      setIsLoading(true);
      const validHash = (
        await userContext.axiosInstance.get(
          `consent/validHash/${deleteConsent.id}/${deleteConsent.hash}`
        )
      ).data;
      if (!validHash) {
        showToast("Existe um erro neste consentimento.", "error", 3000);
        return;
      }
      const result = await userContext.blockchain.revoke({
        userId: userContext.user.id,
        hash: deleteConsent.hash,
      });
      //console.log("revoke result", result);
      if (result) {
        showToast("Consentimento finalizado com sucesso", "success", 3000);
        setConsents(
          consents.filter((consent) => consent.id !== deleteConsent.id)
        );
      } else showToast("Erro ao finalizar consentimento", "error", 3000);
    } catch (error) {
      showToast("Erro ao finalizar consentimento", "error", 3000);
      console.error("Error asking consent:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const deletePending = async (id: string) => {
    try {
      const response = await userContext.axiosInstance.delete(
        `consent/delete/${id}`
      );
      if (response.data) {
        setConsents(consents.filter((consent) => consent.id !== response.data));
        showToast("Consentimento eliminado com sucesso!", "success", 3000);
      }
    } catch (error) {
      console.error("Error deleting consent:", error);
      showToast("Erro ao eliminar consentimento.", "error", 3000);
    }
  };

  function subString(fullString: string) {
    return fullString && fullString.length > 40
      ? `${fullString.slice(0, 40)}...`
      : fullString;
  }

  return (
    <>
      <div className="max-w-screen-2xl md:px-12 px-8 pt-24 pb-4 mx-auto transition-all">
        <div className="text-2xl font-bold">
          {userContext?.activeAffiliation
            ? "Histórico de Consentimentos Pedidos"
            : "Histórico de Consentimentos"}
        </div>
        <p className="text-sm text-muted-foreground">
          Consulte do seu histórico de consentimentos.
        </p>
        <div className="flex md:flex-row flex-col-reverse justify-between md:mt-6 mt-4 mb-2">
          <div className="md:w-1/3 w-full">
            <Input
              type="text"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              placeholder="Pesquisar..."
              className=""
            />
          </div>
          <div className="flex md:w-1/2 md:justify-end  items-start py-4 md:py-0">
            <div className="flex space-x-3">
              <Button
                onClick={() => setStatus(ConsentStatus.ACTIVE)}
                variant="ghost"
                className={
                  status === ConsentStatus.ACTIVE ? "bg-neutral-100" : ""
                }
              >
                Ativos
              </Button>
              {!userContext?.activeAffiliation && (
                <Button
                  onClick={() => setStatus(ConsentStatus.FUTURE)}
                  variant="ghost"
                  className={
                    status === ConsentStatus.FUTURE ? "bg-neutral-100" : ""
                  }
                >
                  Futuros
                </Button>
              )}

              {userContext?.activeAffiliation && (
                <Button
                  onClick={() => setStatus(ConsentStatus.PENDING)}
                  variant="ghost"
                  className={
                    status === ConsentStatus.PENDING ? "bg-neutral-100" : ""
                  }
                >
                  Pendentes
                </Button>
              )}

              <Button
                onClick={() => setStatus(ConsentStatus.EXPIRED)}
                variant="ghost"
                className={
                  status === ConsentStatus.EXPIRED ? "bg-neutral-100" : ""
                }
              >
                Expirados
              </Button>

              <div>
                <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                  <DialogContent className="sm:max-w-2xl min-h-8xl">
                    <ConsentCard
                      give={userContext?.activeAffiliation ? false : true}
                      handleDialogClose={handleDialogClose}
                    />
                  </DialogContent>

                  {/* Desktop Trigger */}
                  <div className="hidden md:flex">
                    <DialogTrigger>
                      <Button>
                        <MdAdd size={20} className="mr-1" />
                        {userContext?.activeAffiliation
                          ? "Pedir Consentimento"
                          : "Dar Consentimento"}
                      </Button>
                    </DialogTrigger>
                  </div>

                  {/* Mobile Trigger */}
                  <div className="md:hidden fixed inset-x-0 bottom-0">
                    <div className="flex w-screen justify-center">
                      <DialogTrigger className="flex justify-center items-center w-full h-full p-6 text-white text-xl bg-teal-600 hover:bg-teal-700 transition-all rounded-t-2xl">
                        {userContext?.activeAffiliation
                          ? "Pedir Consentimento"
                          : "Dar Consentimento"}
                      </DialogTrigger>
                    </div>
                  </div>
                </Dialog>
              </div>
            </div>
          </div>
        </div>

        {pendingCount > 0 && !userContext.activeAffiliation && (
          <div className="relative rounded-md mt-5 border border-gray-200 bg-white mb-4">
            <table className="w-full text-sm text-left rtl:text-right text-gray-700">
              <div className="py-4 px-5">
                <span className="flex">
                  <>
                    {pendingCount === 1
                      ? "Tem 1 pedido de acesso pendente."
                      : `Tem ${pendingCount} pedidos de acesso pendentes.`}
                  </>
                  <Dialog>
                    <DialogContent className="sm:max-w-2xl min-h-8xl">
                      <AccessPending setPendingCount={setPendingCount} />
                    </DialogContent>
                    <DialogTrigger>
                      <span className="text-teal-600 cursor-click font-semibold pl-2">
                        Ver pedidos
                      </span>
                    </DialogTrigger>
                  </Dialog>
                </span>
              </div>
            </table>
          </div>
        )}
        <Table>
          <TableHeader>
            <TableRow>
              {userContext.activeAffiliation && <TableHead>Utente</TableHead>}
              <TableHead>Partilhado com</TableHead>
              {!userContext.activeAffiliation && (
                <TableHead>Organização</TableHead>
              )}
              <TableHead>Categorias</TableHead>
              <TableHead>De</TableHead>
              <TableHead>Até</TableHead>
              <TableHead>
                <div
                  className="flex justify-end p-3 mr-4 hover:cursor-pointer"
                  onClick={fetchConsents}
                >
                  <MdOutlineRefresh size={20} />
                </div>
              </TableHead>
            </TableRow>
          </TableHeader>
          {isLoading ? (
            <TableCell colSpan={6}>
              <div className="flex justify-center items-center text-center py-32 h-full">
                <Loading full={false} />
              </div>
            </TableCell>
          ) : (
            <TableBody className="hover:cursor-pointer">
              {searchConsents.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6}>Sem histórico...</TableCell>
                </TableRow>
              )}
              {searchConsents.length > 0 &&
                searchConsents.map((consent: listConsents, index: number) => (
                  <TableRow key={index}>
                    {userContext.activeAffiliation && (
                      <TableCell className="font-medium">
                        <ToolTip
                          children={<div>{consent.sharingName}</div>}
                          tooltip={`${consent.sharingFullName}`}
                        />
                      </TableCell>
                    )}
                    <TableCell>
                      {consent.sharingWithAffiliations
                        .map((a) => a.name)
                        .join(", ").length +
                        consent.sharingWithDepartmentsNames.join(", ").length +
                        (consent.sharingWithAffiliations.length > 0 &&
                        consent.sharingWithDepartmentsNames.length > 0
                          ? 2
                          : 0) >
                      40 ? (
                        <ToolTip
                          children={
                            <div>
                              {consent.sharingWithAffiliations.length > 0 &&
                                subString(
                                  consent.sharingWithAffiliations
                                    .map(
                                      (affiliation: {
                                        name: string;
                                        role: string;
                                      }) => affiliation.name
                                    )
                                    .join(", ")
                                )}
                              {consent.sharingWithDepartmentsNames.length > 0 &&
                                subString(
                                  consent.sharingWithDepartmentsNames.join(", ")
                                )}
                            </div>
                          }
                          tooltip={
                            consent.sharingWithAffiliations
                              .map((affiliation) => affiliation.name)
                              .join(", ") +
                            (consent.sharingWithAffiliations.length > 0 &&
                            consent.sharingWithDepartmentsNames.length > 0
                              ? ", "
                              : "") +
                            consent.sharingWithDepartmentsNames.join(", ")
                          }
                        />
                      ) : (
                        <div>
                          {consent.sharingWithAffiliations.length > 0 &&
                            subString(
                              consent.sharingWithAffiliations
                                .map(
                                  (affiliation: {
                                    name: string;
                                    role: string;
                                  }) => affiliation.name
                                )
                                .join(", ")
                            )}
                          {consent.sharingWithDepartmentsNames.length > 0 &&
                            subString(
                              consent.sharingWithDepartmentsNames.join(", ")
                            )}
                        </div>
                      )}
                      {consent.sharingWithAffiliations.length === 0 &&
                        consent.sharingWithDepartmentsNames.length === 0 && (
                          <>Toda a organização</>
                        )}
                    </TableCell>
                    {!userContext.activeAffiliation && (
                      <TableCell>
                        {consent.sharingWithOrganizationName}
                      </TableCell>
                    )}
                    <TableCell>
                      <div className="flex flex-wrap gap-2 mt-1">
                        {consent.categories.map(
                          (category: dataCategory, index: number) => (
                            <h3
                              key={index}
                              className={`text-gray-600 px-2 py-1 rounded-lg text-xs`}
                              style={{ backgroundColor: "#" + category.color }}
                            >
                              {category.name}
                            </h3>
                          )
                        )}
                      </div>
                    </TableCell>
                    <TableCell>
                      {formatDate(
                        consent.startsAt
                          ? consent.startsAt.toISOString()
                          : new Date().toISOString()
                      )}
                    </TableCell>
                    <TableCell>
                      {formatDate(
                        consent.expiresAt
                          ? consent.expiresAt.toISOString()
                          : consent.startsAt
                          ? new Date(
                              consent.startsAt.getTime() +
                                consent.duration * 3600000
                            ).toISOString()
                          : new Date(
                              Date.now() + consent.duration * 3600000
                            ).toISOString()
                      )}
                    </TableCell>
                    <TableCell>
                      <Dialog>
                        <DialogTrigger asChild>
                          <Button className="mr-2">
                            <FaRegEye />
                          </Button>
                        </DialogTrigger>
                        <DialogContent className="w-full">
                          <Card4Confirmation
                            userAsking={userContext.activeAffiliation?.name}
                            userSharing={
                              userContext.activeAffiliation
                                ? consent.sharingName
                                : userContext.user.profile.name || ""
                            }
                            organizationName={
                              consent.sharingWithOrganizationName
                            }
                            affiliations={consent.sharingWithAffiliations}
                            departmentNames={
                              consent.sharingWithDepartmentsNames
                            }
                            categories={consent.categories}
                            startsAt={consent.startsAt}
                            duration={consent.duration}
                            expiresAt={consent.expiresAt}
                          />
                        </DialogContent>
                      </Dialog>
                      {status === "PENDING" &&
                        userContext.activeAffiliation && (
                          <AlertDialog>
                            <AlertDialogTrigger asChild>
                              <Button variant="outline" className="mr-2">
                                <FaRegTrashAlt />
                              </Button>
                            </AlertDialogTrigger>
                            <AlertDialogContent>
                              <AlertDialogHeader>
                                <AlertDialogTitle>
                                  Confirmar ação
                                </AlertDialogTitle>
                                <AlertDialogDescription>
                                  Tem a certeza que quer apagar o consentimento?
                                </AlertDialogDescription>
                              </AlertDialogHeader>
                              <AlertDialogFooter>
                                <AlertDialogCancel>Cancelar</AlertDialogCancel>
                                <AlertDialogAction
                                  onClick={() => deletePending(consent.id)}
                                >
                                  Confirmar
                                </AlertDialogAction>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialog>
                        )}
                      {(status === "ACTIVE" || status === "FUTURE") && (
                        <>
                          <AlertDialog>
                            <AlertDialogTrigger asChild>
                              <Button
                                variant="outline"
                                onClick={() => setDeleteConsent(consent)}
                              >
                                <FaRegTrashAlt />
                              </Button>
                            </AlertDialogTrigger>
                            <AlertDialogContent>
                              <AlertDialogHeader>
                                <AlertDialogTitle>
                                  Confirmar ação
                                </AlertDialogTitle>
                                <AlertDialogDescription>
                                  {status === "ACTIVE" && (
                                    <>
                                      Tem a certeza que quer terminar o
                                      consentimento?
                                    </>
                                  )}
                                  {status === "FUTURE" && (
                                    <>
                                      Tem a certeza que quer apagar o
                                      consentimento?
                                    </>
                                  )}
                                </AlertDialogDescription>
                              </AlertDialogHeader>
                              <AlertDialogFooter>
                                <AlertDialogCancel>Cancelar</AlertDialogCancel>
                                <AlertDialogAction onClick={revokeConsent}>
                                  Confirmar
                                </AlertDialogAction>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialog>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
        <Pagination className="mt-5">
          <PaginationContent>
            <PaginationItem>
              <PaginationPrevious
                onClick={() => {
                  if (page > 1) setPage(page - 1);
                }}
              />
            </PaginationItem>
            {[...Array(Math.min(3, maxPages))].map((_, i) => (
              <PaginationItem key={i}>
                <PaginationLink
                  onClick={() => setPage(i + 1)}
                  isActive={page === i + 1}
                >
                  {i + 1}
                </PaginationLink>
              </PaginationItem>
            ))}
            {maxPages > 3 && (
              <PaginationItem>
                <PaginationEllipsis />
              </PaginationItem>
            )}
            <PaginationItem>
              <PaginationNext
                onClick={() => {
                  if (page < maxPages) setPage(page - 1);
                }}
              />
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      </div>
    </>
  );
};

export default ListConsents;
