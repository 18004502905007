import React, { memo } from "react";
import { emptyOrStringInLimits } from "../../utils/stringUtils";

interface InputWithinLimitsProps {
  text: string | undefined;
  max: number; // Max is required
  min?: number; // Min is optional
}

const InputWithinLimits: React.FC<InputWithinLimitsProps> = ({
  text,
  max,
  min,
}) => {
  return (
    <div className="relative">
      <p
        className={`relative top-0 right 0 text-xs mt-1 ${
          emptyOrStringInLimits(text, max, min) ? "text-black" : "text-red-500"
        } text-right`}
      >
        {text ? text.length : 0}/{max}
      </p>
    </div>
  );
};

export default memo(InputWithinLimits);
