export const FRONTEND_DOMAIN = "https://apptest.humanos.health";
export const BACKEND_DOMAIN = "https://apiteste.humanos.health";
export const BACKEND_URL = `${BACKEND_DOMAIN}/v0/`;

export const CLIENT_ID = "59adb8214b2922e6b1244b1325eac728";
export const CC_CLIENT_ID = "cdab6ce9ae2a182e2852c5327ba1c762";

export const FACTORY_ADDRESS = "0x035431c6cf4DBd31fA278aB42a4817Abc70DfD8B";
// Humanos user pool
export const CONSENT_ADDRESS = "0x9F9bc6f3544e9Bf4c46ab04b4f6612dB821C0044";

export const BLOCKCHAIN_USERID_SALT = "HUMANOS_BLOCKCHAIN";
export const USERID_SALT = "HUMANOS";

export const COOKIE_NAME = "jwt";

export const CACHE_COOKIE_OPTIONS = {
  // domain: ".humanos.health",
  expires: 5, // 5 days
  path: "/",
};

export const PIN_INACTIVITY = 6000000; // 10 min
export const PIN_TIMEOUT = 3600000; // 1h

export const OTP_SPACING = 30; // seconds

export const minNameLength = 5;
export const profileNameMax = 30;
export const profileNameMaxWords = 3;

export const affiliationNameMax = 30;
export const affiliationNameMinWords = 2;
export const affiliationNameMaxWords = 5;

export const SEARCH_DEBOUNCE_TIME = 1000; // ms 1000 = 1s

export const PAGE_SIZE = 20;
