// React

//rafa
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// External
import axios from "axios";

// Internal
import "./App.css";
import "./globals.css";

import { UserContext, UserProvider } from "../services/user";
import {
  BACKEND_URL,
  PIN_INACTIVITY,
  PIN_TIMEOUT,
} from "../environment/variables";

// Pages
import ListConsents from "../pages/consents/ListConsents";
import RecordsList from "../pages/records/RecordsList";
import RecordsDetail from "../pages/records/RecordsDetail";
import Navbar from "../components/navbar";
import { Toaster } from "../components/ui/toaster";
import Settings from "../pages/settings/settings";
import PatientList from "../pages/records/PatientList";

import Loading from "../components/loading";
import SelectUser from "../pages/general/SelectUser";
import ErrorPage from "../pages/general/ErrorPage";
import Login from "../pages/general/Login";
import Pin from "../pages/general/Pin";
import DicomComponent from "../pages/dicom/DicomComponent";
import debounce from "lodash/debounce";

// Set the base URL globally at the root level of your application
axios.defaults.baseURL = BACKEND_URL;
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.withCredentials = true; // Send cookies with requests
axios.defaults.xsrfCookieName = "XSRF-TOKEN";
axios.defaults.xsrfHeaderName = "X-XSRF-TOKEN";

type ShowPages = "ALL" | "LOAD" | "NOT_VERIFIED" | "PIN" | "NEW_USER";

function AppContent() {
  const location = useLocation();
  const navigate = useNavigate();
  const hideNavbar =
    location.pathname === "/" ||
    location.pathname === "/select" ||
    location.pathname === "/not-found";
  const userContext = useContext(UserContext);
  const [isLoading, setIsLoading] = useState<boolean>(false); // User loaded + page DATA LOADED

  const needsPin: boolean = useMemo(() => {
    if (!userContext?.user) return false;
    if (!userContext.user.lastPin) return true; // Create
    const lastPinTime = new Date(userContext.user.lastPin).getTime(); // Even that is a Date, convert
    return Date.now() - lastPinTime > PIN_TIMEOUT; // Expired ?
  }, [userContext?.user]);

  const showPages: ShowPages = useMemo(() => {
    if (location.pathname === "/" || location.pathname === "/not-found")
      return "ALL";
    if (!userContext?.user) return "LOAD";
    if (userContext.user.creationStatus === "NEW") {
      return "NEW_USER";
    }
    if (userContext.user.identities.length === 0) return "NOT_VERIFIED"; // also covers creation status === PEDNING_FORM
    if (needsPin) return "PIN";
    return "ALL";
  }, [userContext?.user, location, needsPin]);

  // .................. AFFILIATION -> Pages and Changes
  const prevActiveAffiliationRef = useRef(
    userContext?.user ? userContext.activeAffiliation : null
  ); // undefined = utente, null = reload, affiliation = affiliation

  useEffect(() => {
    // CHANGES IN AFFILIATION
    if (!userContext?.user) return;
    const currentPath = location.pathname.split("/")[1];
    if (
      // From utente to affiliation
      prevActiveAffiliationRef.current === undefined &&
      userContext?.activeAffiliation
    )
      navigate("/people");
    if (
      // From affiliation to utente
      prevActiveAffiliationRef.current !== undefined &&
      !userContext?.activeAffiliation &&
      currentPath !== "consents"
    )
      navigate("/consents");
    // Update Affiliation if changed
    if (prevActiveAffiliationRef.current !== userContext?.activeAffiliation) {
      prevActiveAffiliationRef.current = userContext?.activeAffiliation;
    }
  }, [userContext?.activeAffiliation]);

  // .................. PIN
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const inactivityTimeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  // Function Executed on timer trackers, and changes user which will trigger the needPin to activate
  // due to the userContext.user dependency
  const setLastPin = useCallback(
    // Debounce to prevent multiple calls to the server
    debounce(async () => {
      if (!userContext?.user?.lastPin) return;
      const lastPinTime = new Date(userContext.user.lastPin).getTime();
      if (Date.now() - lastPinTime > PIN_TIMEOUT) return;
      try {
        const response = await userContext.axiosInstance.post(
          "/pin/set-last-pin",
          {}
        );
        if (!response.data) throw new Error("No data returned");
        userContext?.setUser({
          ...userContext.user,
          lastPin: new Date(response.data),
        });
      } catch (error) {
        console.error(error);
      }
    }, 500),
    [userContext]
  );

  // Pin inactivity UseEffect
  const inactivityTracker = useCallback(() => {
    if (!userContext?.user || !userContext.user.lastPin) return;
    if (inactivityTimeoutIdRef.current) {
      clearTimeout(inactivityTimeoutIdRef.current);
    }
    if (["/", "/not-found"].includes(location.pathname) || needsPin) return;
    const timeoutId = setTimeout(() => {
      //console.log("Inactivity timeout reached", timeoutId);
      if (!needsPin && inactivityTimeoutIdRef.current === timeoutId) {
        setLastPin();
      }
    }, PIN_INACTIVITY);
    inactivityTimeoutIdRef.current = timeoutId;
  }, [location, needsPin, userContext?.user?.lastPin]);

  useEffect(() => {
    // Add event listeners for user activity
    window.addEventListener("mousemove", inactivityTracker); // dont think it works

    window.addEventListener("keydown", inactivityTracker);
    window.addEventListener("click", inactivityTracker);
    inactivityTracker();
    // Cleanup function to remove event listeners and clear timeout
    return () => {
      if (inactivityTimeoutIdRef.current) {
        clearTimeout(inactivityTimeoutIdRef.current);
      }
      window.removeEventListener("mousemove", inactivityTracker);
      window.removeEventListener("keydown", inactivityTracker);
      window.removeEventListener("click", inactivityTracker);
    };
  }, [inactivityTracker]);

  // Starts a timer on lastPin date change. At the end of the timer, it will force a pin check if still needed
  // Re-starts if user last pin date changed
  const timeoutTracker = useCallback(() => {
    if (!userContext?.user || !userContext.user.lastPin) return;
    if (timeoutId) clearTimeout(timeoutId); // User updated or needs pin from inactivity
    // Start counter if needs it
    if (!needsPin) {
      const lastPinTime = new Date(userContext.user.lastPin).getTime(); // Even that is a Date, convert
      const nextPin = lastPinTime + PIN_TIMEOUT;
      const _timeoutId = setTimeout(() => {
        if (!userContext?.user) return;
        if (!userContext?.user.lastPin) return;
        const lastPinTime = new Date(userContext.user.lastPin).getTime(); // Even that is a Date, convert
        const nextPin = lastPinTime + PIN_TIMEOUT;
        if (nextPin > Date.now()) return;
        // Update user lastPin to force pin check (since if it reaches this point, the pin is expired)
        userContext?.setUser({
          ...userContext.user,
          lastPin: new Date(lastPinTime - 1),
        });
      }, nextPin - Date.now());
      setTimeoutId(_timeoutId);
    }
  }, [userContext?.user?.lastPin, needsPin]); // Set a new timer on lastPin date change. Only update if needed (could already be updated by )

  useEffect(() => {
    timeoutTracker();
  }, [timeoutTracker]);

  return (
    <>
      {showPages !== "ALL" ? (
        <>
          {showPages === "LOAD" && <Loading />}
          {showPages === "NOT_VERIFIED" && <ErrorPage mode={"NOT_VERIFIED"} />}
          {showPages === "PIN" && <Pin />}
          {showPages === "NEW_USER" && <SelectUser />}
        </>
      ) : (
        <>
          {!hideNavbar && userContext?.user && <Navbar isLoading={isLoading} />}
          <div className={isLoading && !hideNavbar ? "" : ""}>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route
                path="/not-found"
                element={<ErrorPage mode="NOT_FOUND" />}
              />
              {userContext?.user && (
                <>
                  <Route path="/consents" element={<ListConsents />} />
                  <Route path="/settings" element={<Settings />} />
                  {userContext?.user.affiliations.length > 0 &&
                    userContext.activeAffiliation && (
                      <>
                        {" "}
                        <Route path="/people" element={<PatientList />} />
                        <Route path="/people" element={<PatientList />} />
                        <Route
                          path="/people/:userId"
                          element={<RecordsList />}
                        />
                        <Route
                          path="/records-detail"
                          element={<RecordsDetail />}
                        />
                        <Route
                          path="/dicom"
                          element={<DicomComponent classes={{}} />}
                        />
                      </>
                    )}
                </>
              )}
              <Route path="*" element={<Navigate to="/not-found" />} />
            </Routes>
          </div>
        </>
      )}
    </>
  );
}

export default function App() {
  return (
    <BrowserRouter>
      <div className="min-h-screen bg-white">
        <ToastContainer />
        <UserProvider>
          <AppContent />
        </UserProvider>
      </div>
      <Toaster />
    </BrowserRouter>
  );
}
